<template>
	<a-modal :visible="visible" :title="`${!edit ? 'New Custom Field' : 'Edit Custom Field'}`" :okText="`${!edit ? 'ADD FIELD' : 'SAVE'}`" cancelText="CANCEL" @cancel="onClose" @ok="submit" :zIndex="1001" centered>
		<bhLoading :show="loading" />
		<a-form-model :model="newField" ref="newField">
			<a-row :gutter="16">
				<a-col :span="24">
					<a-form-model-item label="Name" required prop="name" :rules="req('Please enter the Name')">
						<a-input placeholder="Name" v-model="newField.name" size="large"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="24" class="mb-4">
					<a-checkbox v-model="newField.required">Required</a-checkbox>
				</a-col>
				<a-col :span="24">
					<a-form-model-item prop="type" label="Field Type" required :rules="req('Please select a Field Type')">
						<a-select @change="typeSelected" v-model="newField.type" size="large">
							<a-select-option v-for="t in types" :key="t.value">{{ t.label }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="24" v-if="newField.type == 'multipleChoice'">
					<a-form-model-item>
						<a-checkbox @change="allowMultiple" v-model="newField.multipleAnswer">Allow Multiple Answers</a-checkbox>
					</a-form-model-item>
				</a-col>
				<a-col :span="24" v-if="newField.type === 'multipleChoice' || newField.type === 'checkbox'">
					<a-form-model-item v-for="(option, optionI) in newField.options" :key="optionI"
						:label="`Option #${optionI + 1}`">
						<div class="dF aC">
							<a-input :placeholder="`Option #${optionI + 1}`" v-model="newField.options[optionI]"></a-input>
							<a-icon v-if="newField.options.length - 1 == optionI" @click="addOption" class="ml-3"
								style="cursor:pointer; font-size:20px; color:var(--orange)" type="plus-circle" />
							<a-icon @click="removeOption(optionI)"
								v-if="newField.options.length - 1 != optionI && (optionI != 0 || newField.options.length != 2)"
								type="minus-circle" class="ml-3"
								style="cursor:pointer; font-size:20px; color:var(--orange)" />
						</div>
					</a-form-model-item>
				</a-col>

				<a-col :span="24" v-if="newField.type == 'text'">
					<a-form-model-item prop="defaultAnswer" label="Default Value">
						<a-input v-model="newField.defaultAnswer" size="large"
							placeholder="Enter the Default Value"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="24" v-else-if="newField.type == 'number'">
					<a-form-model-item prop="defaultAnswer" label="Default Value">
						<a-input type="number" v-model="newField.defaultAnswer" size="large"
							placeholder="Enter the Default Value" :default-value="'0'"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="24" v-else-if="newField.type === 'multipleChoice' || newField.type === 'checkbox'">
					<a-form-model-item prop="defaultAnswer" label="Default Value">
						<a-select v-model="newField.defaultAnswer" size="large" placeholder="Select a Default Value"
							:mode="newField.type == 'checkbox' ? '' : newField.type == 'multipleChoice' && newField.multipleAnswer ? 'multiple' : ''">
							<a-select-option v-for="(option, optionI) in filterOptions(newField.options)" :key="optionI"
								:value="option">{{ option }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="24" v-else-if="newField.type === 'date'">
					<a-form-model-item prop="defaultAnswer" label="Default Value">
						<a-date-picker style="width:100%" v-model="newField.defaultAnswer" size="large"
							placeholder="Select a Default Value" />
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
	</a-modal>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'

export default {
	components: { bhLoading },
	watch: {
		visible: {
			handler(val) {
				if (val) {
					if (this.editId != 0) {
						this.edit = true
						let obj = this.customFields.find(x => x.id == this.editId)
						if (obj) this.newField = JSON.parse(JSON.stringify(obj))
					} else {
						this.edit = false
						this.newField.id = Date.now()
					}
				} else {
					this.edit = false
				}
			}
		}
	},
	computed: {
		customFields() {
			if (this.$store.state.models.allSettings && this.$store.state.models.allSettings.app && this.$store.state.models.allSettings.app.options && this.$store.state.models.allSettings.app.options.customFields) return this.$store.state.models.allSettings.app.options.customFields;
			return []
		},
		editId() {
			return this.$store.state.models.customField.id
		},
		visible() {
			return this.$store.state.models.customField.visible
		},
		storedFields() {
			if (this.$store.state.models.allSettings && this.$store.state.models.allSettings.app && this.$store.state.models.allSettings.app.options && this.$store.state.models.allSettings.app.options.customFields) return this.$store.state.models.allSettings.app.options.customFields
			return [];
		}
	},
	data() {
		return {
			edit: false,
			newField: {
				name: '',
				type: 'text',
				required: false,
				multipleAnswer: false,
				defaultAnswer: '',
				options: [''],
				id: 0,
				date: 0
			},
			loading: false,
			types: [
				{ value: 'text', label: 'Text' },
				{ value: 'number', label: 'Number' },
				{ value: 'checkbox', label: 'Check Box' },
				{ value: 'multipleChoice', label: 'Multiple Choice' },
				{ value: 'date', label: 'Date' }
			],
		}
	},
	methods: {
		submit() {
			this.$refs.newField.validate(valid => {
				if (valid) {
					let sendObj = {}
					if (this.newField.type == 'text' || this.newField.type == 'number' || this.newField.type == 'date') {
						sendObj.name = this.newField.name
						sendObj.type = this.newField.type
						sendObj.required = this.newField.required
						sendObj.defaultAnswer = this.newField.defaultAnswer
						sendObj.id = this.newField.id
						if (!this.edit) sendObj.date = Date.now()
						else sendObj.date = this.newField.date
						sendObj.user = {
							firstName: this.$store.state.user.user.firstName,
							lastName: this.$store.state.user.user.lastName,
							id: this.$store.state.user.user.id
						}
					} else if (this.newField.type == 'multipleChoice' || this.newField.type === 'checkbox') {
						let validSend = true
						this.newField.options.forEach(x => {
							if (x == '') {
								validSend = false
								return this.$message.error('Please fill in all the options!')
							}
						})
						if (validSend) {
							sendObj = this.newField
							sendObj.required = this.newField.required
							sendObj.user = {
								firstName: this.$store.state.user.user.firstName,
								lastName: this.$store.state.user.user.lastName,
								id: this.$store.state.user.user.id
							}
							sendObj.id = this.newField.id
							if (!this.edit) sendObj.date = Date.now()
							else sendObj.date = this.newField.date
						}
					}
					if (!this.edit) {
						if (!this.storedFields.length && Object.keys(sendObj).length != 0) {
							this.loading = true
							this.$api.post(`/settings/:instance/models`, { options: { customFields: [sendObj] } }).then(({ data }) => {
								this.$store.commit('UPDATE_SETTINGS', data)
								this.onClose()
								this.loading = false
							}).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						} else if (this.storedFields.length && Object.keys(sendObj).length != 0) {
							let fields = JSON.parse(JSON.stringify(this.storedFields))
							fields.push(sendObj)
							this.loading = true
							this.$api.post(`/settings/:instance/models`, { options: { customFields: fields } }).then(({ data }) => {
								this.$store.commit('UPDATE_SETTINGS', data)
								this.onClose()
								this.loading = false
							}).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						}
					} else {
						let fields = []
						if (Object.keys(sendObj).length != 0) {
							this.customFields.forEach(field => {
								if (field.id == sendObj.id) {
									fields.push(sendObj)
								} else {
									fields.push(field)
								}
							})
						}
						this.loading = true
						this.$api.put(`/settings/:instance/models`, { options: { customFields: fields } }).then(({ data }) => {
							this.$store.commit('UPDATE_SETTINGS', data)
							this.onClose()
							this.loading = false
						}).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					}
				}
			})
		},
		onClose() {
			this.$store.commit('CLOSE_FIELD')
			this.$refs.newField.resetFields()
			this.newField = {
				name: '',
				type: 'text',
				required: false,
				multipleAnswer: false,
				defaultAnswer: '',
				options: [''],
				id: 0,
				date: 0
			}
		},
		req(msg) {
			return { required: true, message: msg }
		},
		typeSelected(e) {
			if (e == 'text') {
				this.newField.defaultAnswer = ''
			} else if (e == 'number') {
				this.newField.defaultAnswer = 0
			} else if (e == 'checkbox' || e == 'multipleChoice') {
				this.newField.defaultAnswer = ''
				this.newField.options = ['']
			} else if (e == 'date') {
				this.newField.defaultAnswer = moment(Date.now())
			}
		},
		allowMultiple(e) {
			if (e.target.checked) {
				this.newField.defaultAnswer = []
			} else {
				this.newField.defaultAnswer = ''
			}
		},
		removeOption(index) {
			this.newField.options.splice(index, 1)
		},
		addOption() {
			let pushNew = true
			this.newField.options.forEach(x => {
				if (x == '' || !x.trim()) {
					pushNew = false
					return this.$message.error('Please fill in all the options!')
				}
			})
			if (pushNew) {
				this.newField.options.push('')
			}
		},
		filterOptions(arr) {
			return arr.filter(x => x != '')
		},
	}
}
</script>

<style>
</style>
